/** npm import */

import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from "react-bootstrap";
import { CKEditor } from "ckeditor4-react";

/** local file import */
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";

/** code start */

function PrivacyPolicy() {

    return (
        <>
            <Header title="Privacy policy" />

            <div className="common_page_scroller">
                <Row>
                    <Col xs={12} lg={7} md={8} sm={10} className='mb-4'>
                        <div className='rp_singleinput_holder'>
                            <p className='rp_label mb-2'>Add Title</p>
                            <div className='d-flex align-items-center gap-3 '>

                                <div className='rp_input_holder w-100 rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                    <input type='text' placeholder='Privacy Policy' id="price" name="price" className='rp_singleInput flex-grow-1'
                                    // value={formValue.price}
                                    // onChange={(event) => handlechange(event)}
                                    />

                                </div>
                                <button className='orange_primary_btn'>Add</button>
                            </div>
                            {/* <span className="errors_style" id="price-error" >error</span> */}

                        </div>
                        <div className="mt-4">
                            <CKEditor
                                config={{
                                    //extraAllowedContent: 'div(*)',
                                    allowedContent: true,
                                    height: 500,
                                }}
                                initData="<p>This is an example CKEditor 4 WYSIWYG editor instance.</p>"
                            // onChange={handleEditorChange}
                            />
                        </div>
                    </Col>
                </Row>



            </div>
        </>
    );
}

export default PrivacyPolicy;
