import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FaEdit } from 'react-icons/fa';
import { FaPlus, FaTrash } from "react-icons/fa6";
import { adminAddcategory, adminAddHobbies, getHobbieslists } from "../../api/api";
import { useState } from "react";
import toast from 'react-hot-toast';
import EditCategory from "../../Modals/EditCategory";
import EditSubcategory from "../../Modals/EditSubcategory";

const SubcategoryDrag = ({ index, records, Getsubcategorylistdata }) => {
  console.log("rectototototo", records);
  const [showEditCategory, setShowEditCategory] = useState(false);
  const [showcategory, setShowcategory] = useState({})
  const handleCloseEditCategory = () => {
    setShowEditCategory(false)
  }
  const handleShowEditCategory = (record) => {
    console.log("records", record);
    setShowEditCategory(true)
    setShowcategory(record)
  }


  const id = records?._id
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <>
            <EditSubcategory show={showEditCategory} handleClose={handleCloseEditCategory} EditData={showcategory} editfunc={Getsubcategorylistdata} />

      <tr ref={setNodeRef} style={style}>
        <td {...attributes} {...listeners}>{index + 1}</td>
        <td {...attributes} {...listeners}>{records?.categoryId?.category || "-"}</td>
        <td {...attributes} {...listeners}>{records?.subcategory || "-"}</td>
        <td className="drag-table__actions">


          <button className='green_edit_button'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              console.log("Delete button clicked");
              handleShowEditCategory(records);
            }}
          >
            <FaEdit className='ms-1' />
          </button>

        </td>
      </tr >
    </>);
};

export default SubcategoryDrag;
